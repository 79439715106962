@import 'styles/variables';
@import 'styles/mixins';

.page404 {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  @include for-mobile {
    padding: 0 12px;
  }

  .bg {
    width: 100%;
    height: 336px;
    margin-bottom: 48px;
    background-image: url('../../public/assets/404-page/404-desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include for-mobile {
      background-image: url('../../public/assets/404-page/404-mobile.png');
      margin-bottom: 40px;
    }
  }

  h1 {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.29;

    @include for-mobile {
      margin-bottom: 26px;
      text-align: center;
    }
  }

  .btn {
    @include for-mobile {
      width: 100%;
    }
  }
}
