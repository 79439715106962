@mixin for-mobile-4xs {
  @media (max-width: 335px) {
    @content;
  }
}

@mixin for-mobile-3xs {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin for-mobile-xxs {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin for-mobile-xs {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin for-mobile {
  @media (max-width: 765px) {
    @content;
  }
}

@mixin for-xs-tablet {
  @media (min-width: 766px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 1021px) {
    @content;
  }
}

@mixin for-less-tablet {
  @media (max-width: 1021px) {
    @content;
  }
}

@mixin for-xs-tablet-and-tablet {
  @media (min-width: 766px) and (max-width: 1020px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1206px) {
    @content;
  }
}

@mixin for-less-desktop {
  @media (max-width: 1206px) {
    @content;
  }
}

@mixin for-mobile-tablet-and-xs-tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin for-large-desktop {
  @media (min-width: 1512px) {
    @content;
  }
}

/* EVERYTHING THAT IS BELOW THIS COMMENT IS USED FOR NEW PERSONAL ACCOUNT */
@mixin mobile {
  @media (max-width: 764px) {
    @content;
  }
}

@mixin for-small-height {
  @media screen and (max-height: 700px) {
    @content;
  }
}

@mixin account-content-inner-space {
  padding-right: 68px;
  padding-left: 50px;

  @include mobile {
    padding: 0 16px;
  }
}

@mixin account-cards-list-inner-space {
  padding-top: 16px;
  padding-left: 25px;
}

@mixin only-for-safari {
  @supports (-webkit-backdrop-filter: blur(1px)) {
    @content;
  }
}

@mixin only-for-hover {
  @media (hover: hover) {
    @content;
  }
}

@mixin gradient-text($gradient) {
  background-image: $gradient;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
